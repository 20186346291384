export const menu = {
    PRODUCTS: {TITLE: "Products", URL: "/#/products?name=Orange_Juice&variant=15.2oz_bottle"},
    // BUY_NOW: {TITLE: "Buy Now", URL: "#",
    // VENDORS: [
    //     {TITLE:"Retailer FPO", URL:"#"},
    //     {TITLE:"Retailer FPO2", URL:"#"},
    // ]},
    // LOCATOR: {TITLE: "Product Locator", URL: "https://contact.pepsico.com/dolejuice/product-locator"},
    // SERVE_DOLE: {TITLE: "Serve Dole Juice", URL: "https://www.pepsicopartners.com/pepsico/en/USD/BEVERAGES/Juices-%26-Shakes/c/juicesShakes?q=%3Arelevance%3AdisplayCategory%3Atrue%3AlocationAvailibility%3Atrue%3Abrand%3Abrand_dole%3AdisplayCategory%3Atrue%3AlocationAvailibility%3Atrue%3AdisplayCategory%3Atrue%3AlocationAvailibility%3Atrue&productType=Product&text=&page=0"},
    CONTACT: {TITLE: "Contact", URL: "https://contact.pepsico.com/tropicanabrandsgroup"},
    // JUICE_URL: {TITLE: "Dole Lemonade", URL: "https://dolelemonade.com/"},
}

export const menu_products = [
    {img: "orange-juice-menu@2x.png", bgUrl: "orange-juice-menu-bg.png", name: "Dole Orange Juice"},
    {img: "tfp-menu@2x.png", bgUrl: "tfp-menu-bg.png", name: "Dole Tropical Fruit Punch"},
    {img: "apple-juice-menu@2x.png", bgUrl: "apple-juice-menu-bg.png", name: "Dole Apple Juice"}
]

export const carousel_card = {
    LEARN_MORE: "Learn More",
}

export const ctas = {
    FIND_IT: "Find It",
    BUY_IT: "Buy It"
}

export const carousel_products = [
    { 
        NAME: "Dole Lemonade", 
        DESC: "Phasellus gravida ornare augue sed rhoncus. Mauris vitae lorem quam. Donec id volutpat ex. Sed hendrerit tincidunt rhoncus.",  
        IMG: "group-103@2x.png", 
        FIND_IT_URL: "", 
        BUY_IT_URL: ""
    },
    { 
        NAME: "Dole Strawberry Lemonade", 
        DESC: "Phasellus gravida ornare augue sed rhoncus. Mauris vitae lorem quam. Donec id volutpat ex. Sed hendrerit tincidunt rhoncus.",  
        IMG: "dole-lemonade-strawberry@2x.png", 
        FIND_IT_URL: "", 
        BUY_IT_URL: ""
    }
]

export const mixology = {
    BODY: "Phasellus gravida ornare augue sed rhoncus. Mauris vitae lorem quam. Donec id volutpat ex. Sed hendrerit tincidunt rhoncus.",
    BUTTON: "Check it out",
    LOAD_MORE: "LOAD MORE RECIPES",
    SORT: "Filter By",
    FILTERS: [
        "Most Recent",
        "Name",
        "Flavor"
    ],
    FLAVORS: [
        "Apple",
        "Orange",
        "Grapefruit",
        "Punch"
    ]
}

export const recipe =  {
    BACK: "BACK TO RECIPES",
    TOTAL_PREP: "Total Prep Time: ",
    INGREDIENTS: "Ingredients",
    DIRECTIONS: "Directions"
}

export const ageCheck = {
    DISCLAIMER: "This site is intended for those of legal drinking age. By visiting the Dole Mixology site, you affirm that you are of legal drinking age in the country where the site is being accessed."
}

export const Module = {
    mixology: {
        header: "mixology"
    }
}

export const siteSwap = {
    TITLE: "Real Lemonade / Real Delicious",
    BUTTON: {TITLE:"Dole Lemonade", URL:"https://dolelemonade.com/"}
}

export const footer = {
    ABOUT_US:{TITLE: "About Us", URL: "https://www.tropicanabrandsgroup.com/about-us/"},
    PRIVACY:{TITLE: "Privacy Policy", URL: "https://www.tropicanabrandsgroup.com/privacy-policy/"},
    TERMS: {TITLE: "Terms of Use", URL: "https://www.tropicanabrandsgroup.com/terms-of-use/"},
    CONTACT: {TITLE: "Contact", URL: "https://www.tropicanabrandsgroup.com/contactus/"}
}

export const nutritionfacts_apple = {
  CALORIES: 210,
  TOTAL_FAT:{mg: 0, percent: 0},
  SODIUM:{mg: 20, percent: 1},
  TOTAL_CARBS:{
    mg: 51000, percent: 20,
    TOTAL_SUGAR:{
      mg: 48000,
      ADDED_SUGAR:{mg: 0, percent: 0}
    },
  },
  PROTEIN:{mg: 1000},
  CALCIUM:{mg: 38, percent: 2},
  POTASSIUM:{mg: 470, percent: 10},
  VITAMIN_C:{mg: 114, percent: 120},
  VITAMIN_B6:{mg: 0.1, percent: 6},
  DISCLAMER:"Not a significant source of saturated fat, <em>trans</em> fat, cholesterol, dietary fiber, vitamin D, and iron.",
  LEGEND: "* The % Daily Value (DV) tells you how much a nutrient in serving food contributes to a daily diet. 2,000 calories a day is used for general nutrition advice."
}

export const nutritionfacts_grapefruit = {
  CALORIES: 250,
  TOTAL_FAT:{mg: 0, percent: 0},
  SODIUM:{mg: 30, percent: 1},
  TOTAL_CARBS:{
    mg: 59000, percent: 21,
    TOTAL_SUGAR:{
      mg: 50000,
      ADDED_SUGAR:{mg: 0, percent: 0}
    },
  },
  PROTEIN:{mg: 2000},
  CALCIUM:{mg: 30, percent: 2},
  IRON:{mg: 0.4, percent: 2},
  POTASSIUM:{mg: 350, percent: 6},
  VITAMIN_C:{mg: 90, percent: 100},
  DISCLAMER:"Not a significant source of saturated fat, <em>trans</em> fats, cholesterol, dietary fiber, and vitamin D.",
  LEGEND: "* The % Daily Value (DV) tells you how much a nutrient in serving food contributes to a daily diet. 2,000 calories a day is used for general nutrition advice."
}

export const nutritionfacts_orange = {
  CALORIES: 210,
  TOTAL_FAT:{mg: 0, percent: 0},
  SODIUM:{mg: 30, percent: 1},
  TOTAL_CARBS:{
    mg: 51000, percent: 19,
    TOTAL_SUGAR:{
      mg: 49000,
      ADDED_SUGAR:{mg: 0, percent: 0}
    },
  },
  PROTEIN:{mg: 4000},
  CALCIUM:{mg: 42, percent: 2},
  POTASSIUM:{mg: 850, percent: 15},
  VITAMIN_C:{mg: 114, percent: 120},
  FOLATE:{mg: 0.12, percent: 30},
  DISCLAMER:"Not a significant source of saturated fat, <em>trans</em> fat, cholesterol, dietary fiber, vitamin D, and iron.",
  LEGEND: "* The % Daily Value (DV) tells you how much a nutrient in serving food contributes to a daily diet. 2,000 calories a day is used for general nutrition advice."
}


export const nutritionfacts_tfp = {
  CALORIES: 210,
  TOTAL_FAT:{mg: 0, percent: 0},
  SODIUM:{mg: 40, percent: 2},
  TOTAL_CARBS:{
    mg: 52000, percent: 19,
    TOTAL_SUGAR:{
      mg: 49000,
      ADDED_SUGAR:{mg: 0, percent: 0}
    },
  },
  PROTEIN:{mg: 3000},
  CALCIUM:{mg: 30, percent: 2},
  POTASSIUM:{mg: 410, percent: 8},
  VITAMIN_C:{mg: 110, percent: 120},
  VITAMIN_E:{mg: 4, percent: 25},
  NIACIN:{mg: 4, percent: 25},
  DISCLAMER:"Not a significant source of saturated fat, <em>trans</em> fat, cholesterol, dietary fiber, vitamin D, and iron.",
  LEGEND: "* The % Daily Value (DV) tells you how much a nutrient in serving food contributes to a daily diet. 2,000 calories a day is used for general nutrition advice."
}