import React from 'react'
import "./footer.scss"
import {footer} from "../../constants"
import logo from "./../../assets/high-level/dole-logo@3x.png" 

const Footer = () => {

    const nav = Object.keys(footer).map((item, i) => {
        const {TITLE, URL} = footer[item]
        return (
        <li key={i}><a href={URL} target="_blank">{TITLE}</a></li>
        )
    })

    return (
        <div className="footer">
            <div className="footer__container">
                <img src={logo} alt="Dole® logo"/>
                <ul>{nav}</ul>
            </div>
        </div>
    )
}

export default Footer
