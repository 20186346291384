import React, { useState, useEffect } from 'react'
import './nutritionalFacts.scss'

const NutritionalFacts = ({ facts, onClick, color }) => {
  const [factsStored, setFacts] = useState(facts)
  const [isNutriFactsOpen, setIsNutriFactsOpen] = useState(false)

  const toggleNutriFactsAccordion = () => {
    setIsNutriFactsOpen(!isNutriFactsOpen);
    onClick()
  }

  const arrow = (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="10" viewBox="0 0 25 42.554">
      <g>
        <path fill="#5281c2" d="M102.157 19.616L121.094.68a2.333 2.333 0 0 1 3.292 0l1.395 1.394a2.331 2.331 0 0 1 0 3.292l-15.9 15.9L125.8 37.188a2.333 2.333 0 0 1 0 3.293l-1.4 1.393a2.333 2.333 0 0 1-3.292 0L102.157 22.92a2.349 2.349 0 0 1 0-3.3z" transform="translate(-101.478)" />
      </g>
    </svg>
  )

  useEffect(()=>{
    setFacts(facts)
  },[facts])

  const {
    CALORIES,
    TOTAL_FAT,
    SODIUM,
    TOTAL_CARBS,
    PROTEIN,
    CALCIUM,
    IRON,
    POTASSIUM,
    VITAMIN_C,
    VITAMIN_B6,
    VITAMIN_E,
    FOLATE,
    NIACIN,
    DISCLAMER,
    LEGEND

  } = factsStored

  return (
    <>
      <button className={`nutrition__button ${isNutriFactsOpen ? "active" : ""}`} onClick={() => toggleNutriFactsAccordion()}>
        Nutrition Facts
        <div>{arrow}</div>
      </button>
      <div className={`nutrition__container ${isNutriFactsOpen ? "active" : ""}`}>
        <div className="nutrition__border">
          <table className="nutrition__table">
            <tr>
              <td colSpan="2" className={`title ${color}`}>Nutrition Facts</td>
            </tr>
            <tr>
              <td colSpan="2">1 serving per container</td>
            </tr>
            <tr  className='divider_none'>
              <td><span>Serving size</span></td>
              <td className="right no-wrap"><span>15.2 fl oz <br/>(450 mL)</span></td>
            </tr>
            <tr className='divider_bold'>
              <td colSpan="2">Amount Per Serving</td>
            </tr>
            <tr className='divider_none'>
              <td className={`cals ${color}`}>Calories</td>
              <td className={`cals right ${color}`}>{CALORIES | 0}</td>
            </tr>
            <tr  className='divider_medium'>
              <td colSpan="2" className="line2 right"><span>% Daily Value*</span></td>
            </tr>
            <tr>
              <td><span>Total Fat</span> {TOTAL_FAT.mg | 0}g</td>
              <td className="right"><span>{TOTAL_FAT.percent | 0}%</span></td>
            </tr>
            <tr>
              <td><span>Sodium</span> {SODIUM.mg | 0}mg</td>
              <td className="right"><span>{SODIUM.percent | 0}%</span></td>
            </tr>
            <tr>
              <td><span>Total Carbohydrate</span> {TOTAL_CARBS.mg/1000 | 0}g</td>
              <td className="right"><span>{TOTAL_CARBS.percent | 0}%</span></td>
            </tr>
            <tr>
              <td className="indent" colSpan="2">Total Sugars {TOTAL_CARBS.TOTAL_SUGAR.mg/1000 | 0}g</td>
            </tr>
            <tr>
              <td className="indent2">Includes {TOTAL_CARBS.TOTAL_SUGAR.ADDED_SUGAR.mg | 0}g <br/> Added Sugars</td>
              <td className="right"><span>{TOTAL_CARBS.TOTAL_SUGAR.ADDED_SUGAR.percent | 0}%</span></td>
            </tr>
            <tr>
              <td><span>Protein</span> {PROTEIN.mg/1000 | 0}g</td>
            </tr>
            <tr className="divider_bold">
              <td>Calcium {CALCIUM.mg | 0}mg</td>
              <td className="right">{CALCIUM.percent | 0}%</td>
            </tr>
            {IRON ?         
            <tr>
              <td>Iron {IRON.mg}mg </td>
              <td className="right">{IRON.percent | 0}%</td>
            </tr>
            : ""}
            <tr>
              <td>Potassium {POTASSIUM.mg | 0}mg</td>
              <td className="right">{POTASSIUM.percent | 0}%</td>
            </tr>
            <tr>
              <td>Vitamin C {VITAMIN_C.mg | 0}mg</td>
              <td className="right">{VITAMIN_C.percent | 0}%</td>
            </tr>
            {FOLATE ?         
            <tr>
              <td>Folate {FOLATE.mg * 1000 | 0}mcg DFE</td>
              <td className="right">{FOLATE.percent | 0}%</td>
            </tr>
            : ""}
            {VITAMIN_E ?         
            <tr>
              <td>Vitamin E {VITAMIN_E.mg | 0}mg</td>
              <td className="right">{VITAMIN_E.percent | 0}%</td>
            </tr>
            : ""}
            {VITAMIN_B6 ?         
            <tr>
              <td>Vitamin B6 {VITAMIN_B6.mg}mg</td>
              <td className="right">{VITAMIN_B6.percent | 0}%</td>
            </tr>
            : ""}
            {NIACIN ?         
            <tr>
              <td>Niacin {NIACIN.mg | 0}mg</td>
              <td className="right">{NIACIN.percent | 0}%</td>
            </tr>
            : ""}
            <tr className="divider_medium">
              <td colSpan="2" dangerouslySetInnerHTML={{__html: DISCLAMER}}></td>
            </tr>
            <tr>
              <td colSpan="2" className='legend'>{LEGEND}</td>
            </tr>
          </table>
        </div>
      </div>
    </>
  )
}

export default NutritionalFacts