import React, {useEffect} from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { recipe } from '../../constants'
import recipeList from './../../recipes.json'
import './recipe.scss'
import garnish from './../../assets/recipes/group-211.png'

const Recipe = ({hideTrees}) => {

    const {TOTAL_PREP, BACK, INGREDIENTS, DIRECTIONS} = recipe

    const recipe404 = {
        title: "Oops: No Recipe Found", 
        desc: "", 
        image: "component-80-2.png",
        ingredients:[], 
        directions: {},
        time: ""
    }

    useEffect(() => {
        hideTrees(true)

        return(()=> {
            hideTrees(false)
        })
    }, [])

    const location = useLocation().search.substring(1)
    const history = useHistory()

    const backToMixology = () => {
        history.push("/mixology")
    }

    const parseQueryString = (qry) => {
        const pairs = qry.split('&')
        return pairs.reduce((acc, curr) => {
            const keyValue =curr.split('=')
            const key = keyValue[0]
            const value = keyValue[1].split("_").join(" ")
            acc[key] = value
            return acc
        },{})
    }

    const SearchForRecipe = () => {
        const {name} = parseQueryString(location)
        const nameAccented = name.replace("Jalapeno" ,"Jalapeño")
        return recipeList.recipes.find(recipe => {
            return recipe.title === nameAccented
        }) || recipe404
    }

    const { title, ingredients, directions, time, image } = SearchForRecipe()
    const imgSrc = require(`./../../assets/recipes/${image}`).default


    const ingredientsList = ingredients.map((item, i) => {
        return(<li key={i}>{item}</li>)
    })

    const dirctionsList = Object.keys(directions).map((dir, i) => {
        return(<li key={i}>{directions[dir]}</li>)
    })

    return (
        <div className="recipe-page">
            <div className="recipe-page__image">
                <img src={imgSrc} alt={title} />
            </div>
            <div className = "recipe-page__time">
                <p>{TOTAL_PREP}{time}</p>
            </div>
            <div className="recipe-page__container">
                <div className="recipe-card">
                    <div className="recipe-card__content">
                        <h1>{title}</h1>
                        {(ingredients.length) ? <h2>{INGREDIENTS}</h2> :""}
                            <ul>{ingredientsList}</ul>
                        {(Object.keys(directions).length) ? <h2>{DIRECTIONS}</h2> : ""}
                            <ol>{dirctionsList}</ol>
                    </div>
                    <div className="recipe-card__photo">
                        <img src={imgSrc} alt={title} />
                        <div className="recipe-card__time">
                            <p>{TOTAL_PREP}{time}</p>
                        </div>
                    </div>
                    <img className="recipe-page__lemon" src={garnish} alt="garnish" />
                </div>
                <button onClick={()=> backToMixology()}>{BACK}</button>
            </div>
        </div>
    )
}

export default Recipe
