import React from 'react'
import './buyIt.scss'
import Modal from '../Modal/Modal'
import walmartLogo from './../../assets/walmart_logo.png'

const BuyIt = ({openModal, isModalOpen}) => {

    return (
        <div className={`buy-it ${(isModalOpen)? "open" : ""}`}>
            <Modal onClick={()=> openModal(false)}>
                <img src={walmartLogo} alt="Walmart Logo"/>
            </Modal>
        </div>
    )
}

export default BuyIt
