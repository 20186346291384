import React from 'react'
import "./ageChecker.scss"
import { ageCheck } from './../../constants'
import logo from './../../assets/dole-logo.png'
import Modal from "./../../components/Modal/Modal"

const AgeChecker = ({onClick, className}) => {
    return (
        <div className={`age-checker ${className}`}>
            <Modal className="age-checker__modal" closeButton={false}>
                {/* <button className="age-checker__close" onClick={()=> onClick()}>close</button> */}
                <img src={logo} alt="Dole logo"/>
                <p>{ageCheck.DISCLAIMER}</p>
                <div className="button-group">
                    <button onClick={()=> onClick(true)}>Yes</button>
                    <button onClick={()=> onClick(false)}>No</button>
                </div>
            </Modal>
        </div>
    )
}

export default AgeChecker
