import React from 'react'
import './modal.scss'

const Modal = ({children, onClick, closeButton = true}) => {

    return (
        <>
            <div className="modal">
                {(closeButton) ? <button className="ctas__close" onClick={()=> onClick()}>close</button> : ""}
                {children}
            </div>
            <div className="modal__bg"></div>
        </>
    )
}

export default Modal
