import React, { useState } from 'react';
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import './App.css';
import AgeModal from './components/AgeChecker/AgeChecker';
import BuyIt from './components/BuyIt/BuyIt';
import Footer from './components/Footer/Footer';
import Menu from './components/Menu/Menu';
import Header from './components/Header/Header';
import Home from './pages/Home/Home';
import Product from './pages/Product/Product';
import Mixology from './pages/Mixology/Mixology';
import Recipe from './pages/Recipe/Recipe';
import recipeList from './recipes.json'

function App() {
  const [isAgeModalOpen, setIsAgeModalOpen] = useState(false)
  const [isBuyModalOpen, setIsBuyModalOpen] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [shouldBlur, setShouldBlur] = useState(false)
  const [isTreesHidden, setIsTreesHidden] = useState(false)
  const [recipes, setRecipes] = useState(recipeList.recipes)

  const toggleAgechecker = (isOpen) => setIsAgeModalOpen(isOpen)
  const toggleMenu = (isOpen) => setIsMenuOpen(isOpen)

  const hideTrees = (isHidden) => setIsTreesHidden(isHidden)


  const validateAge = (isLegal) => {
    if(isLegal === null){
      toggleAgechecker(true)
      setShouldBlur(true)
    } else {
      filterOutCocktails(isLegal)
    }    
  }

  const storeLegals = (legals) => {
    toggleAgechecker(false)
    setShouldBlur(false)
    filterOutCocktails(legals)
    window.sessionStorage.setItem("isLegal", legals)
  }

  const filterOutCocktails = (isLegal) => {
    const ageList = (isLegal) ? recipes : recipes.filter(item => {
      return item.cocktail === false
    })
    setRecipes(ageList);
  }

  const toggleModal = (isOpen) => {
    setIsBuyModalOpen(isOpen)
    setShouldBlur(isOpen)
  }

  return (
    <Router>
      <div className={`App ${(isMenuOpen || isAgeModalOpen)?"open" :""}`}>
        <AgeModal className={(isAgeModalOpen)?"open" :""} onClick={(isLegal)=> storeLegals(isLegal)} isOpen={isAgeModalOpen}/>
        <BuyIt isOpen={shouldBlur} isModalOpen={isBuyModalOpen} openModal={(isOpen)=> toggleModal(isOpen)}/>
        <Menu isOpen={isMenuOpen} onClick={()=> toggleMenu(false)} />
        <div className={(shouldBlur)?"blur" :""}>
          <Header onClick={() => toggleMenu(true)} className={(isTreesHidden) ? "hide": ""} />
          <div className="pages">
            <Switch>
              <Route path="/" exact>
                <Home openChecker={()=> toggleAgechecker(true)} onClick={(isBlurred)=> toggleModal(isBlurred)}/>
              </Route>
              <Route path="/products">
                <Product onClick={(isBlurred)=> toggleModal(isBlurred)}/>
              </Route>
              {/* <Route path="/mixology">
                <Mixology recipes={recipes} validateAge={(storage)=> validateAge(storage)} hideTrees={(isHidden)=> hideTrees(isHidden)}/>
              </Route> */}
              <Route path="/recipe">
                <Recipe hideTrees={(isHidden)=> hideTrees(isHidden)} />
              </Route>
            </Switch>
          </div>
          <Footer/>
        </div>
      </div>
    </Router>
  );
}

export default App;
