import React, {useState, useEffect} from 'react'
import { useHistory } from "react-router-dom";
import CarouselCard from '../../components/CarouselCard/CarouselCard'
import prodList from './../../products.json'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel as ReactCarousel } from 'react-responsive-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import './carousel.scss'
import lemon from './../../assets/lemons-leaves-no-shadow.png'



const Carousel = ({onClick, selectedProduct}) => {
    const history =  useHistory()
    const [itemInView, setItemInView] = useState(0)
    const [hide, setHide] = useState("")

    const cards = prodList.products.map((card, i)=> {
        const imgSrc = require(`./../../assets/products/${card.img}`).default
        return (<div key={i} id={i} onClick={()=>goToProductPage()}><img src={imgSrc} alt={card.name}/></div>)
    })

    const changeItem = (index) => {
        hidePeekProduct()
        setItemInView(index)
        selectedProduct(prodList.products[index])
    }

    const peekingProducts = (() => {
        const indexLoopPrev = prodList.products[itemInView - 1] || prodList.products[prodList.products.length-1]
        const indexLoopNext = prodList.products[itemInView + 1] || prodList.products[0]
        return {
            previous: require(`./../../assets/products/${indexLoopPrev.img}`).default,
            previous_name: indexLoopPrev.name,
            next: require(`./../../assets/products/${indexLoopNext.img}`).default,
            next_name: indexLoopNext.name,
        }
    })()

    const hidePeekProduct = () => {
        setHide("hide")
        setTimeout(() =>{
            setHide("")
        },500)
    }

    const goToProductPage = () => {
      history.push(`/products?name=${prodList.products[itemInView].name.split(' ').join("_")}&variant=${prodList.products[itemInView].activeVariant.split(' ').join("_")}`)
  }

    return (
        <div className="carousel-mod">
            <ReactCarousel 
            infiniteLoop={true}
            showThumbs={false}
            autoPlay={false}
            showStatus={false}
            onChange={(e)=> changeItem(e)}
          >
                {cards}
            </ReactCarousel> 
            <img id="prevProd" className={hide} src={peekingProducts.previous} alt={peekingProducts.previous_name}/>
            <img id="nextProd" className={hide} src={peekingProducts.next} alt={peekingProducts.next_name}/>
            <CarouselCard product={prodList.products[itemInView]} onClick={(isBlurred)=>onClick(isBlurred)}/>
        </div>
    )
}

export default Carousel
