import React from 'react'
import { useHistory } from "react-router-dom";
import './carouselCard.scss'
import { carousel_card } from './../../constants'
import CTAs from '../CTAs/CTAs'
import underline from './../../assets/carousel/circle-pattern.png'

const CarouselCard = ({product, onClick}) => {
    const history =  useHistory()
    const {LEARN_MORE} = carousel_card
    const {name, desc, activeVariant, isComingSoon, findUrl, buyUrl} = product
    const classColor = name.toLowerCase().split(" ").join("-")

    const goToProductPage = () => {
        history.push(`/products?name=${name.split(' ').join("_")}&variant=${activeVariant.split(' ').join("_")}`)
    }

    return (
        <div className='carousel-card'>
            <div className="carousel-card__content">
                <h3 onClick={()=>goToProductPage()}>{name}
                    <img src={underline} alt=""/>
                </h3>
                <p>{desc}<br />
                    <a onClick={()=>goToProductPage()}>{LEARN_MORE} &gt;</a>
                </p>
                {/* <CTAs onClick={(isModalOpen)=> onClick(isModalOpen)} findUrl={findUrl} buyUrl={buyUrl}/>  */}
            </div>
            <div className="carousel-card__paper">
                <div className={`carousel-card__title ${classColor}`}></div>
            </div>
            <div className={`coming-soon ${(isComingSoon) ? "show" : ""}`}></div>
        </div>
    )
}

export default CarouselCard
