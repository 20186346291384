import React, {useState} from 'react'
import { useHistory } from "react-router-dom";
import "./menu.scss"
import { menu, menu_products } from "../../constants"
import logo from './../../assets/high-level/dole-logo@2x.png'

const Menu = ({isOpen, onClick}) => {
    const history = useHistory()
    const [isBuyNowOpen, setIsBuyNowOpen] = useState(false)

    const goHome = () => {
        history.push("/")
        onClick()
    }
    
    const vendorList = (list) => {
        return list.map((item, i)=> {
            const {TITLE, URL} = item;
            return (
                <li key={i}><a onClick={()=> onClick()} href={URL}>{TITLE}</a></li>
            )
        })
    }

    const nav = Object.keys(menu).map((item, i) => {
        const {TITLE, URL, VENDORS} = menu[item]
        if(TITLE !== "Buy Now") {
            return (
                <li key={i}><a onClick={()=> onClick()} href={URL}>{TITLE}</a></li>
            )
        }
        else {
            return (
                <li key={i}><a onClick={()=> setIsBuyNowOpen(!isBuyNowOpen)}>{TITLE}</a>
                    <ul className={(isBuyNowOpen)? "open" : ""}>
                        {vendorList(VENDORS)}
                    </ul>
                </li>
            )
        }
    })

    const products = menu_products.map((products, i) => {
        let url = (require(`./../../assets/menu/${products.img}`));
        let bgUrl = (require(`./../../assets/menu/${products.bgUrl}`));
        return (
            <div key={i} className="product">
                <img src={url.default} alt=""/>
                <img className="bg" src={bgUrl.default} alt="" />
            </div>
        )
    })

    return (
        <div className={`menu ${(isOpen)? "open": ""}`}>
            <div className="menu__nav">
            <button onClick={()=> onClick()}>Menu</button>
                <img onClick={()=> goHome()} src={logo} />
                <ul>{nav}</ul>
                
            </div>
            <div className="menu__products">            
                { products }
            </div>
        </div>
    )
}

export default Menu