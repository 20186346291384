import React, { useEffect, useState } from 'react'
import "./hero.scss"
import bottles from "./../../assets/modules/juice-bottle-lockup-mobile@2x.png"

const Hero = () => {

    const [presentFruit, setPresentFruit] = useState(false)

    const parallax = (e) => {
        const el = document.querySelector(".hero-mod")
        const desktop = window.matchMedia('(min-width: 1024px)')
        let middleXAxis = window.innerWidth/2
        let middleYAxis = window.innerHeight/2
        let mouseX = e.clientX
        let mouseY = e.clientY
        
        let pineapple = `${-10 - (middleXAxis - mouseX) * -0.002}% ${-40 - (middleYAxis - mouseY) * -0.002}%`
        let orange = `${-10 - (middleXAxis - mouseX) * -0.004}% ${100 - (middleYAxis - mouseY) * -0.004}%`

        let cherry = `${90 - (middleXAxis - mouseX) * -0.003}% ${-45 - (middleYAxis - mouseY) * -0.003}%`
        let apple = `${109 - (middleXAxis - mouseX) * -0.005}% ${50 - (middleYAxis - mouseY) * -0.005}%`


         let newPositions = `${pineapple}, ${cherry}, ${orange}, ${apple}`

        if(desktop.matches) el.style.backgroundPosition = newPositions;
    }


    useEffect(() => {
        
        document.addEventListener('mousemove', parallax)
        document.addEventListener('DOMContentLoaded', (event) => setPresentFruit(true))
        
        return(() => {
            document.removeEventListener('mousemove', parallax)
            document.removeEventListener('DOMContentLoaded', (event) => setPresentFruit(true))
        })
        
    }, [])

    return (
        <div className={`hero-mod ${(presentFruit) ? "slide" : ""}`}>
            <div className="hero-mod__header" />
            <img src={bottles} alt="Dole Orange Juice, Tropical Fruit Punch, and Apple Juice Bottles"/>
        </div>
    )
}

export default Hero
