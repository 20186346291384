import React from 'react'
import "./header.scss"
import logo from './../../assets/high-level/dole-logo@3x.png'
import { useHistory } from 'react-router-dom'

const Header = ({onClick, className}) => {
    const history = useHistory()
    const goHome = () => history.push("/")

    return (
        <div className={`header ${className}`}>
            {/* <div className="header__foreground-tree header__foreground-tree--left"></div>
            <div className="header__foreground-tree header__foreground-tree--right"></div> */}
            <img src={logo} alt="Dole Logo" onClick={()=> goHome()} />
            <button onClick={()=> onClick()}>Menu</button>
        </div>
    )
}

export default Header
