import React, {useEffect} from 'react'
import { useLocation } from "react-router-dom";
import './product.scss'
import Carousel from '../../modules/Carousel/Carousel'
import ProductDetails from '../../modules/ProductDetail/ProductDetails'
import NutritionalFacts from '../../components/NutritionalFacts/NutritionalFacts';
import { nutritionfacts_apple, nutritionfacts_grapefruit, nutritionfacts_orange, nutritionfacts_tfp } from './../../constants'

const Product = ({onClick}) => {
    const location = useLocation().search.substring(1)

    const changeProduct = (selected) => {
        return ""
    }




    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
    }, [changeProduct, location])


    return (
        <div className="product-page">
            <ProductDetails onClick={(isModalOpen) => onClick(isModalOpen)} location={location}/>
            <Carousel onClick={(isModalOpen) => onClick(isModalOpen)} selectedProduct={(selected)=> changeProduct(selected) }/>
        </div>
    )
}

export default Product
