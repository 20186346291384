import React, {useEffect} from 'react'
import Hero from '../../modules/Hero/Hero'
import Mixology from '../../modules/Mixology/Mixology'
import Carousel from '../../modules/Carousel/Carousel'
import Seasonal from '../../modules/Seasonal/Seasonal'
import SiteSwap from '../../modules/SiteSwap/SiteSwap'

const Home = ({openChecker, onClick}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div className="home-page">
            <Hero />
            <Carousel onClick={(isBlurred)=>onClick(isBlurred) } selectedProduct={(selected)=> console.log(selected) }/>
            {/* <Mixology /> */}
            {/* <Seasonal /> */}
            {/* <SiteSwap /> */}
        </div>
    )
}

export default Home
