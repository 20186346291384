import React, {useState} from 'react'
import './productDetails.scss'
import CTAs from '../../components/CTAs/CTAs'
import productList from './../../products.json'
import NutritionalFacts from '../../components/NutritionalFacts/NutritionalFacts'
import { nutritionfacts_apple, nutritionfacts_grapefruit, nutritionfacts_orange, nutritionfacts_tfp } from './../../constants'

const ProductDetails = ({details, onClick, location}) => {
  const [isNutriFactsOpen, setIsNutriFactsOpen] = useState(false)

  const toggleNutriFactsAccordion = () => setIsNutriFactsOpen(!isNutriFactsOpen)

    const parseQueryString = (qry) => {
        const pairs = qry.split('&')
        return pairs.reduce((acc, curr) => {
            const keyValue =curr.split('=')
            const key = keyValue[0]
            const value = keyValue[1].split("_").join(" ")
            acc[key] = value
            return acc
        },{})
    }

    const SearchForProduct = () => {
        const {name} = parseQueryString(location)
        const nameAccented = name.replace("Jalapeno" ,"Jalapeño")
        return productList.products.find(product => {
            return product.name === nameAccented
        })
    }

    const getNutritionFacts = () => {
      const {name} = parseQueryString(location)
      switch (name) {
        case "Orange Juice": return nutritionfacts_orange;
        case "Grapefruit Juice": return nutritionfacts_grapefruit;
        case "Apple Juice": return nutritionfacts_apple;
        case "Tropical Fruit Punch": return nutritionfacts_tfp
      }
    }

    const {name, variation, desc, img, activeVariant, findUrl, buyUrl } = SearchForProduct()
    const classColor = name.toLowerCase().split(" ").join("-")

    const variant = variation.map((type, i) => {
        return (<button key={i} className={(activeVariant === type) ? "active" : ""}>{type}</button>)
    })

    const src = require(`./../../assets/products/${img}`).default

    return (
        <div className={`product-details-mod ${isNutriFactsOpen ? "active" : ""}`}>
            <div className="product-details-mod__clouds"></div>
            <div className="product-details-mod__container">
                <img src={src} alt={`${name} ${variation}`} />
                <div className="product-details-mod__content">
                    <h3 className={classColor}>{name}</h3>
                    {/* <div className="product-details-mod__button-group">
                        {variant}
                    </div> */}
                    <p>{desc}</p>
                    {/* <CTAs onClick={(isModalOpen) => onClick(isModalOpen)} findUrl={findUrl} buyUrl={buyUrl}/> */}
                    <NutritionalFacts facts={()=>getNutritionFacts()} onClick={()=>toggleNutriFactsAccordion()} color={classColor}/>
                </div>
            </div>
        </div>
    )
}

export default ProductDetails
